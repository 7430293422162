import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import Overview from '../Overview/Overview';
import Summary from '../Summary/Summary';
import { getOverViewCount, getThreadCount } from '../../common/repository';
import SearchComponent from '../Search/SearchComponent';
import ClubModal from '../ClubModal/ClubModal';
import Operations from '../Operations/Operations';
import Secondary from '../Secondary/Secondary';

const Dashboard = () => {
    const [thread, setThread] = useState(0);
    const [user, setUser] = useState(0);
    const [numberOfComment, setNumberofComments] = useState(0);

    useEffect(() => {
        const getUserCount = async () => {
            try {
                // Fetch the count of users
                getOverViewCount('users').then((data) => {
                    setUser(data);
                });

                // Fetch the count of comments
                getOverViewCount('comments').then((data) => {
                    setNumberofComments(data);
                });

                getThreadCount('comments').then((data) => {
                    setThread(data);
                });
            } catch (error) {
                console.error("Error fetching user count: ", error);
            }
        };

        getUserCount();
    }, []);

    return (

        <div className="main-content">
            
            <h1>Dashboard</h1>
            <div className='search-operations-container'>

          
            <div className='search-container-dashboard'>
            <SearchComponent status = "comments" placeholder={"Search by website url"}/>
    
            </div>
           <div className='club-operation-container'>
           <Operations />
           <ClubModal />
           <Secondary />
           </div>
            </div>
            <Overview thread={thread} user={user} comments={numberOfComment} />
            <Summary />
        </div>

    );
};

export default Dashboard;
