import React, { useEffect, useState } from 'react';
import './Secondary.css';
import axiosInstance from '../../common/axiosInstance';
import { toast } from 'react-toastify';
import { Delete } from '@mui/icons-material';

function Secondary() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [domains, setDomains] = useState([]);


  useEffect(() => {
    if (isModalOpen) {
      fetchDomains();
    }
  }, [isModalOpen]);

  const fetchDomains = async () => {
    try {
      const { data, status } = await axiosInstance.get('/secondary');
      if (status === 200) {
        setDomains(data);
      } else {
        toast.error('Failed to fetch domains.');
      }
    } catch (error) {
      console.error('Error fetching domains:', error);
      toast.error('An error occurred while fetching domains.');
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);



  const handleDeleteDomain = async (domainId) => {
    try {
      const { status } = await axiosInstance.delete(`/secondary/delete/${domainId}`);
      if (status === 200) {
        setDomains((prev) => prev.filter((domain) => domain.id !== domainId));
        toast.success('Domain deleted successfully!');
      } else {
        toast.error('Failed to delete domain.');
      }
    } catch (error) {
      console.error('Error deleting domain:', error);
      toast.error('An error occurred while deleting the domain.');
    }
  };

  return (
    <div style={{marginLeft : '10px'}}>
      <button className="operations-button" onClick={handleOpenModal}>
      Secondary
      </button>

      {isModalOpen && (
        <div className="operations-modal-overlay" onClick={handleCloseModal}>
          <div className="operations-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="operations-close-button" onClick={handleCloseModal}>
              ×
            </button>
            <p className="detailed-report-text">Secondary URL</p>

            {/* <form className="input-section" onSubmit={addDomain}>
              <input
                type="text"
                placeholder="Enter Domain"
                value={newDomain}
                onChange={(e) => setNewDomain(e.target.value)}
                className="domain-input"
              />
              <button type="submit" className="add-button">
                Add
              </button>
            </form> */}



            <div className="operations-table-container">
              <table className="operations-custom-table">
                <thead>
                  <tr>
                    <th>URL</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {domains.map((domain) => (
                    <tr key={domain.id}>
                      <td>{domain.url}</td>
                      <td>
                        <button
                          className="operations-delete-button"
                          onClick={() => handleDeleteDomain(domain.id)}
                          aria-label="Delete Domain"
                        >
                          <Delete style={{ color: 'red' }} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Secondary;
