import React, { useEffect, useState } from 'react';
import './Operations.css';
import axiosInstance from '../../common/axiosInstance';
import { toast } from 'react-toastify';
import { Delete } from '@mui/icons-material';

function Operations() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [domains, setDomains] = useState([]);
  const [newDomain, setNewDomain] = useState('');
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const [isSecondary, setIsSecondary] = useState(false); // State for Secondary ID checkbox

  // Fetch domains when the modal is opened
  useEffect(() => {
    if (isModalOpen) {
      fetchDomains();
    }
  }, [isModalOpen]);

  const fetchDomains = async () => {
    try {
      const { data, status } = await axiosInstance.get('/whitelist');
      if (status === 200) {
        setDomains(data);
      } else {
        toast.error('Failed to fetch domains.');
      }
    } catch (error) {
      console.error('Error fetching domains:', error);
      toast.error('An error occurred while fetching domains.');
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const addDomain = async (e) => {
    e.preventDefault();
    if (!newDomain.trim()) {
      toast.error('Domain cannot be empty.');
      return;
    }
  
    try {
      const { status } = await axiosInstance.post('/whitelist/add', {
        domain: newDomain,
        isChecked,
        isSecondary, // Add isSecondary flag
      });
      if (status === 200) {
        // Manually create the new domain object
        const newDomainObject = {
          id: new Date().getTime(), // Generate a temporary unique ID for the new domain
          domain: newDomain,        // The new domain entered by the user
          isChecked : isChecked,                // Use the current checkbox state
          isSecondary: isSecondary,              // Use the current secondary checkbox state
        };
        
        // Add the new domain to the domains array
        setDomains((prev) => [...prev, newDomainObject]);
        
        // Reset form fields
        setNewDomain('');
        setIsChecked(false); // Reset checkbox state
        setIsSecondary(false); // Reset Secondary checkbox state
        toast.success('Domain added successfully!');
      } else {
        toast.error('Failed to add domain.');
      }
    } catch (error) {
      console.error('Error adding domain:', error);
      toast.error('An error occurred while adding the domain.');
    }
  };
  

  const handleDeleteDomain = async (domainId) => {
    try {
      const { status } = await axiosInstance.delete(`/whitelist/delete/${domainId}`);
      if (status === 200) {
        setDomains((prev) => prev.filter((domain) => domain.id !== domainId));
        toast.success('Domain deleted successfully!');
      } else {
        toast.error('Failed to delete domain.');
      }
    } catch (error) {
      console.error('Error deleting domain:', error);
      toast.error('An error occurred while deleting the domain.');
    }
  };

  return (
    <div>
      <button className="operations-button" onClick={handleOpenModal}>
        Operations
      </button>

      {isModalOpen && (
        <div className="operations-modal-overlay" onClick={handleCloseModal}>
          <div className="operations-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="operations-close-button" onClick={handleCloseModal}>
              ×
            </button>
            <p className="detailed-report-text">Manage Domains</p>

            <form className="input-section" onSubmit={addDomain}>
              <input
                type="text"
                placeholder="Enter Domain"
                value={newDomain}
                onChange={(e) => setNewDomain(e.target.value)}
                className="domain-input"
              />
              <button type="submit" className="add-button">
                Add
              </button>
            </form>

            <div className="checkbox-section">
              <input
                type="checkbox"
                id="checkbox"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label htmlFor="checkbox"> Enable URL</label>
            </div>

            <div className="checkbox-section">
              <input
                type="checkbox"
                id="secondary-checkbox"
                checked={isSecondary}
                onChange={(e) => setIsSecondary(e.target.checked)}
              />
              <label htmlFor="secondary-checkbox"> Secondary ID</label>
            </div>

            <div className="operations-table-container">
              <table className="operations-custom-table">
                <thead>
                  <tr>
                    <th>Domain</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {domains.map((domain) => (
                    <tr key={domain.id}>
                      <td>{domain.domain}</td>
                      <td>
                        {domain.isSecondary
                          ? 'Secondary'
                          : domain.isChecked
                          ? 'Considered'
                          : 'Whitelisted'}
                      </td>
                      <td>
                        <button
                          className="operations-delete-button"
                          onClick={() => handleDeleteDomain(domain.id)}
                          aria-label="Delete Domain"
                        >
                          <Delete style={{ color: 'red' }} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Operations;
